import {
  required,
  helpers,
  maxLength,
  minLength,
  email,
  sameAs,
  between
} from '@vuelidate/validators'

const TITLE_MAX_LENGTH = 150
const FIRST_NAME_MAX_LENGTH = 50
const LAST_NAME_MAX_LENGTH = 50
const EMAIL_MAX_LENGTH = 320
const PASSWORD_MIN_LENGTH = 8
const PASSWORD_MAX_LENGTH = 35
const COMMENTS_MAX_LENGTH = 320

export const betweenValueValidator = (fieldName = 'Название', minValue = 0, maxValue = 12262) => ({
  between: helpers.withMessage(
    `Поле "${fieldName}" должно быть в диапазоне от ${minValue} до ${maxValue}`,
    between(minValue, maxValue)
  )
})

export const SNumberInputValidator = (fieldName = 'Название', minValue = 0, maxValue = 12262) => ({
  required: helpers.withMessage(`Поле "${fieldName}" обязательно для заполнения`, required),
  between: helpers.withMessage(
    `Поле "${fieldName}" должно быть в диапазоне от ${minValue} до ${maxValue}`,
    between(minValue, maxValue)
  )
})

export const titleValidator = (fieldName = 'Название') => {
  return {
    title: {
      required: helpers.withMessage(`Поле "${fieldName}" обязательно для заполнения`, required),
      maxLength: helpers.withMessage(
        `Убедитесь, что значение в поле "${fieldName}" не превышает ${TITLE_MAX_LENGTH} символов`,
        maxLength(TITLE_MAX_LENGTH)
      )
    }
  }
}

export const firstNameValidator = {
  first_name: {
    required: helpers.withMessage('Имя обязательно для заполнения', required),
    maxLength: helpers.withMessage(
      `Имя должно содержать максимум ${FIRST_NAME_MAX_LENGTH} символов`,
      maxLength(FIRST_NAME_MAX_LENGTH)
    )
  }
}

export const lastNameValidator = {
  last_name: {
    required: helpers.withMessage('Фамилия обязательна для заполнения', required),
    maxLength: helpers.withMessage(
      `Фамилия должна содержать максимум ${LAST_NAME_MAX_LENGTH} символов`,
      maxLength(LAST_NAME_MAX_LENGTH)
    )
  }
}

export const emailValidator = {
  email: {
    email: helpers.withMessage('Введите корректный адрес электронной почты', email),
    maxLength: helpers.withMessage(
      `Электронная почта должна содержать максимум ${EMAIL_MAX_LENGTH} символов`,
      maxLength(EMAIL_MAX_LENGTH)
    )
  }
}

export const passwordValidator = (data) => ({
  password: {
    minLength: helpers.withMessage(
      `Пароль должен содержать минимум ${PASSWORD_MIN_LENGTH} символов`,
      minLength(PASSWORD_MIN_LENGTH)
    ),
    maxLength: helpers.withMessage(
      `Пароль должен содержать максимум ${PASSWORD_MAX_LENGTH} символов`,
      maxLength(PASSWORD_MAX_LENGTH)
    )
  },
  confirmPassword: {
    sameAsPassword: helpers.withMessage('Пароли должны совпадать', sameAs(data.password))
  }
})

export const commentValudator = {
  comments: {
    maxLength: helpers.withMessage(
      `Комментарий должен содержать ${COMMENTS_MAX_LENGTH} символов`,
      maxLength(COMMENTS_MAX_LENGTH)
    )
  }
}
