import db from '../'
import cloneDeep from 'lodash.clonedeep'

export const checkStorage = async (table, idb, server, filter = {}, noUpdate = false) => {
  const { field, value } = filter
  const parsedServer = cloneDeep(server)?.map((e) => {
    e.server_id = cloneDeep(e.id)
    delete e.id
    return e
  })

  const onlyOnServe =
    parsedServer?.filter((p) => {
      return !idb?.find((e) => e.server_id === p.server_id)
    }) || []
  const deletedFromServer = idb
    .filter((e) => {
      return e.server_id && !parsedServer?.find((p) => p.server_id === e.server_id)
    })
    ?.map((e) => e.id)

  try {
    if (deletedFromServer?.length) {
      await db[table].bulkDelete(deletedFromServer)
    }

    if (onlyOnServe?.length) {
      let idbData = onlyOnServe

      if (field && value) {
        idbData = onlyOnServe.map((e) => {
          e[field] = value
          return e
        })
      }
      await db[table].bulkAdd(idbData)
    }

    if (!noUpdate) {
      if (deletedFromServer?.length || onlyOnServe?.length) {
        await db.updateStoreData(table, filter)
      }
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const updateStorage = (table, idb, server, filter = {}, noUpdate = false) => {
  const parsedServer = cloneDeep(server)?.map((e) => {
    e.server_id = e.id
    delete e.id
    return e
  })
  const items =
    parsedServer
      ?.filter((p) => {
        return idb?.find((e) => e.server_id === p.server_id)
      })
      .map((e) => {
        return {
          ...e,
          id: idb?.find((c) => c.server_id === e.server_id)?.id
        }
      })
      .filter((e) => e.id) || []

  updateOnIdb(table, items, filter, noUpdate)
}

const updateOnIdb = async (table, items, filter = {}, noUpdate = false) => {
  const { field, value } = filter
  try {
    let idbData = items
    if (field && value) {
      idbData = items.map((e) => {
        e[field] = value
        return e
      })
    }

    let objects

    if (table === 'objects') {
      objects = await Promise.all(
        idbData.map(async (item) => {
          const dbItem = await db.objects.get({ id: item.id })
          return { ...dbItem, ...item }
        })
      )
    } else {
      objects = idbData
    }

    await db[table].bulkPut(objects)
    if (!noUpdate) {
      await db.updateStoreData(table, filter)
    }
  } catch (e) {
    throw new Error(e)
  }
}
