<script setup>
import { ref } from 'vue'
import SWikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'
import SoilSelectComp from './soil-select-comp.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['trigger-changed', 'has-error'])

const initSource = ref(props.source)

const wikiTip = {
  color: 'placeholder',
  title: 'Шлиры',
  description:
    'Включения льда в мерзлом грунте, представленные прожилками, прослойками, линзами разной ориентировки, образующие криогенные текстуры. Максимальная толщина шлиров условно принимается равной 0.3-0.5 м; при большей толщине образования следует называть ледяными пластами или линзами, которые уже не входят в понятие криогенной текстуры',
  source:
    'Рекомендации по методике изучения подземных льдов и криогенного строения многолетнемерзлых грунтов, 1986'
}

const handleError = (value) => {
  emits('has-error', value)
}
</script>

<template>
  <div class="create-guide-text-pfrost">
    <div class="create-guide-text-pfrost__item">
      <soil-select-comp
        :source="source"
        :soil-interval="soilInterval"
        :field="field"
        no-comment
        @trigger-changed="emits('trigger-changed', $event)"
      />
    </div>
    <div v-if="initSource[field.id] && !field.rock" class="create-guide-text-pfrost__item">
      <div class="create-guide-text-pfrost__item-title">
        <s-title type="small"> Укажите информацию о шлирах </s-title>
        <s-wiki-tip :data="wikiTip">
          <s-icon name="fa-circle-question" :color="`var(--${wikiTip.color})`" />
        </s-wiki-tip>
      </div>
      <s-text> Ширина шлиров, мм </s-text>
      <div class="create-guide-text-pfrost__item-row">
        <s-number-input
          v-model="initSource.ice_schliere_width_from"
          :min="0"
          :no-blur="true"
          :max="initSource.ice_schliere_width_to || 12262"
          @has-error="handleError"
          placeholder="От"
        />
        <s-text class="create-guide-text-pfrost__item-dash" style="position: absolute">–</s-text>
        <s-number-input
          v-model="initSource.ice_schliere_width_to"
          :min="initSource.ice_schliere_width_from"
          :max="12262"
          :no-blur="true"
          @has-error="handleError"
          placeholder="До"
        />
      </div>
      <s-text> Расстояние между шлирами, мм </s-text>
      <div class="create-guide-text-pfrost__item-row">
        <s-number-input
          v-model="initSource.ice_schliere_interval_from"
          :min="0"
          :no-blur="true"
          :max="initSource.ice_schliere_interval_to || 12262"
          @has-error="handleError"
          placeholder="От"
        />
        <s-text class="create-guide-text-pfrost__item-dash">–</s-text>
        <s-number-input
          v-model="initSource.ice_schliere_interval_to"
          :min="initSource.ice_schliere_interval_from"
          :max="12262"
          :no-blur="true"
          @has-error="handleError"
          placeholder="До"
        />
      </div>
    </div>
    <s-input
      v-if="field.commentField"
      v-model="initSource[field.commentField]"
      type="textarea"
      :label="field.commentTitle || 'Комментарий'"
    />
  </div>
</template>
<style lang="scss">
.create-guide-text-pfrost {
  display: grid;
  grid-gap: 2rem;

  &__item {
    display: grid;
    grid-gap: 0.5rem;

    &-title {
      display: flex;
      justify-content: space-between;
    }

    &-dash {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-row {
      position: relative;
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: calc(50% - 16px) calc(50% - 16px);
      align-items: start;
      justify-content: space-between;
    }
  }
}
</style>
