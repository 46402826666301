<script setup>
import { computed } from 'vue'
import itemCell from './item-cell.vue'

const props = defineProps({
  data: {
    type: Array,
    default: null
  },
  headerConfig: {
    type: Array,
    default: null
  }
})

defineEmits(['select-change'])

const indeterminateState = computed(() => {
  return props.data.some((e) => !e.checked)
})

const checkedState = computed(() => {
  return props.data.every((e) => e.checked)
})
</script>

<template>
  <div class="e-list-header">
    <item-cell key="action" :width="36">
      <s-checkbox
        :checked="checkedState"
        :indeterminate="indeterminateState"
        @change="$emit('select-change', checkedState, indeterminateState)"
      />
    </item-cell>
    <item-cell
      v-for="cell in headerConfig"
      :key="cell.type"
      :width="cell.width"
      :stretched="cell.stretched"
      :center="cell.center"
    >
      <s-text type="secondary">
        {{ cell.label }}
      </s-text>
    </item-cell>
  </div>
</template>

<style lang="scss">
.e-list-header {
  display: flex;
  align-items: center;
  padding: 8px 0;

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr;
  }
}
</style>
