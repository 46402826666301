<script setup>
import { ref, computed, onMounted, inject, watch } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { useMq } from 'vue3-mq'
import db from '@/libs/db'
import { useRouter } from 'vue-router'
import { getSortedMappedGeologists } from '@/utils'

import { useServicesStore, useMainStore, useExcavationStore, useObjectsStore } from '@/stores'
import { useRequests } from '@/composables'
import {
  excavationTemplate,
  basicItem,
  fieldsList,
  locationFields,
  locationFactFields
} from './configs'
import { useVuelidate } from '@vuelidate/core'
import { titleValidator } from '@/validators'
import { SNumberInputValidator, betweenValueValidator } from '@/validators'

import { createExcavation, editExcavation } from './helpers'
import { loadObject } from '../../../objects/object-editor/helpers'
import { deleteHandler, deleteImages, saveImages, saveVideos, deleteVideos } from '@/utils'
import CollapseItem from '@/components/collapse-item.vue'
import SLocationEditor from '@/components/s-location-editor/s-location-editor.vue'
import ExcavationEditorImage from './components/excavation-editor-images.vue'
import ExcavationEditorVideos from './components/excavation-editor-videos.vue'
import BlockLoader from '@/components/block-loader.vue'

const props = defineProps({
  objectId: {
    type: [String, Number],
    default: null
  },
  title: {
    type: String,
    default: 'Создание выработки'
  },
  excavation: {
    type: Object,
    default: () => null
  },
  isVisible: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['update-handler', 'toggle'])

const { getRequest } = useRequests()
const router = useRouter()
const mq = useMq()
const servicesStore = useServicesStore()
const mainStore = useMainStore()
const excavationStore = useExcavationStore()
const objectsStore = useObjectsStore()
const $notify = inject('$notify')

const loading = ref(false)
const ready = ref(false)
const triggered = ref(false)
const excavationDataTemplate = ref(cloneDeep(excavationTemplate))
const initSource = ref(null)
const images = ref([])
const cloneImages = ref([])
const files = ref([])
const currentOpenedItemId = ref('basic')

const hasChanges = computed(() => {
  return !isEqual(initSource.value, excavationDataTemplate.value)
})

const initObjectId = computed(() => {
  return props.objectId || props.excavation?.object_id
})

const initBasicItem = computed(() => {
  const object = Object.assign({}, basicItem)

  object.fields = basicItem?.fields?.map((e) => {
    if (e.title === 'h_plan') {
      e.caption = `Фактическая глубина - ${
        props.excavation?.h || 0
      } м (считается автоматически при добавлении слоев)`
    }
    return e
  })

  return object
})

const services = computed(() => {
  const { excav, object_bore_machines, object_bore_masters } = servicesStore

  const object_users = getSortedMappedGeologists()

  return {
    ...excav,
    object_bore_machines,
    object_bore_masters,
    object_users
  }
})

const disabled = computed(() => {
  const basic = basicItem?.fields
    ?.filter((e) => e.required)
    ?.some(
      (e) => excavationDataTemplate.value[e.title] !== 0 && !excavationDataTemplate.value[e.title]
    )

  const locationFieldsLocal = !props.excavation ? locationFields : locationFactFields
  const location = locationFieldsLocal
    ?.filter((e) => {
      if (excavationDataTemplate.value.id && excavationDataTemplate.value.status === 1) {
        return false
      } else {
        return e.required
      }
    })
    ?.some((e) => !excavationDataTemplate.value[e.title])

  const info = fieldsList
    .map((e) => e.fields)
    ?.filter((e) => e.required)
    .some((e) => !excavationDataTemplate.value[e.title])

  return basic || location || info
})

const requiredFields = computed(() => {
  if (!triggered.value) return []

  const basic = basicItem?.fields
    ?.filter(
      (e) =>
        e.required &&
        excavationDataTemplate.value[e.title] !== 0 &&
        !excavationDataTemplate.value[e.title]
    )
    .map((e) => e.title)
  const locationFieldsLocal = !props.excavation ? locationFields : locationFactFields
  const location = locationFieldsLocal
    ?.filter((e) => {
      if (excavationDataTemplate.value.id && excavationDataTemplate.value.status === 1) {
        return false
      } else {
        return e.required && !excavationDataTemplate.value[e.title]
      }
    })
    .map((e) => e.title)
  const info = []
  fieldsList.forEach((e) => info.push(...e.fields))
  const infoRequired = info
    ?.filter((e) => e.required && !excavationDataTemplate.value[e.title])
    .map((e) => e.title)

  return [...basic, ...location, ...infoRequired]
})

const init = async () => {
  if (props.excavation) {
    excavationDataTemplate.value = await cloneDeep(props.excavation)
    await loadImages()

    if (mainStore.isOnline) {
      await loadVideos()
    }
  }
  ready.value = true
  initSource.value = cloneDeep(excavationDataTemplate.value)
}

const loadImages = async () => {
  loading.value = true

  const { server_id, id } = props.excavation

  try {
    const imagesLocal = []
    if (mainStore.isOnline && !mainStore.noSyncMode && server_id) {
      const response = await getRequest(`excavations/${server_id}/images/`)
      response.forEach((e) => {
        imagesLocal.push(e)
      })
    }
    const idb = await db.images
      .where({
        table: 'excavations',
        item_id: id
      })
      .toArray()

    idb.forEach((e) => {
      imagesLocal.push({
        ...e,
        idb: true
      })
    })

    images.value = imagesLocal || []
    cloneImages.value = cloneDeep(images.value)
  } catch (e) {
    console.log(e)
    $notify({
      message: `Произошла ошибка при загрузке фотографий. ${e}`,
      type: 'error'
    })
  } finally {
    loading.value = false
  }
}

const checkIsWarning = (fields) => {
  if (!triggered.value) return false
  if (!fields?.length) return false

  const required = fields?.filter((e) => e.required)

  if (!required?.length) return false

  return required.some(
    (e) => excavationDataTemplate.value[e.title] !== 0 && !excavationDataTemplate.value[e.title]
  )
}

const toggleModal = (action) => {
  if (action) {
    emits('update-handler')
  }
  emits('toggle')
  triggered.value = false
}

const object = computed(() => {
  return objectsStore?.activeObject
})

const isShowedNotify = ref(false)

const createHandler = async () => {
  const result = await v$.value.$validate()

  if (!result) {
    $notify({
      type: 'error',
      message: 'Не заполнены обязательные поля'
    })
    isShowedNotify.value = true
    isWarning.value = true
    return
  }

  loading.value = true
  isWarning.value = false

  const filter = { field: 'object_id', value: initObjectId.value }

  try {
    if (!props.excavation) {
      await createExcavation(
        initObjectId.value,
        excavationDataTemplate.value,
        loading.value,
        toggleModal,
        filter
      )

      await loadObject(object.value)
    } else {
      await editExcavation(excavationDataTemplate.value, loading.value, toggleModal, filter)
    }
  } finally {
    loading.value = false
    isShowedNotify.value = false
    if (cloneImages.value?.length) {
      updateImages()
    }

    if (files.value?.length) {
      await saveImagesLocal()
    }

    if (cloneVideos.value?.length) {
      updateVideos()
    }

    if (videoFiles.value.length) {
      await saveVideosLocal()
    }
  }
}

const saveImagesLocal = async () => {
  const { server_id, id } = props.excavation

  const data = {
    server_id,
    table: 'excavations',
    item_id: id,
    files: files.value,
    excavation_id: server_id || `idb_${id}`
  }

  await saveImages(data)
  excavationStore.setField('updateExcavationImages', true)
}

const updateImages = async () => {
  try {
    const deleted = cloneImages.value?.filter((c) => !images.value.find((im) => im.id === c.id))
    const { server_id, id } = props.excavation

    const data = {
      table: 'excavations',
      files: deleted,
      excavation_id: server_id || `idb_${id}`,
      source: props.excavation
    }

    mainStore.setIsImagesLoading(true)
    await deleteImages(data)
    excavationStore.setField('updateExcavationImages', true)
  } catch (e) {
    console.log(e)
    $notify({
      message: `Произошла ошибка при удалении фотографий. ${e}`,
      type: 'error'
    })
  }
}

const deleteHandlerLocal = async () => {
  loading.value = true

  try {
    await deleteHandler(props.excavation, 'excavations', goToObject)
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
    toggleModal()
  }
}

const goToObject = () => {
  router.push(`/app/data/objects/${props.excavation.object_id}`)
}

const changeActiveCollapse = (id) => {
  currentOpenedItemId.value = id
}

const videos = ref([])
const cloneVideos = ref([])
const videoFiles = ref([])

const loadVideos = async () => {
  loading.value = true

  const { server_id } = props.excavation

  try {
    const response = await getRequest(`excavations/${server_id}/videos/`)
    response.forEach((e) => {
      videos.value.push(e)
    })

    cloneVideos.value = cloneDeep(videos.value)
  } catch (e) {
    console.log(e)
    $notify({
      message: `Произошла ошибка при загрузке видео. ${e}`,
      type: 'error'
    })
  } finally {
    loading.value = false
  }
}

const saveVideosLocal = async () => {
  const { server_id } = props.excavation

  const data = {
    server_id,
    files: videoFiles.value
  }

  await saveVideos(data)
  excavationStore.setField('updateExcavationVideos', true)
}

const updateVideos = async () => {
  try {
    const deleted = cloneVideos.value?.filter((c) => !videos.value.find((im) => im.id === c.id))

    const data = {
      files: deleted
    }

    await deleteVideos(data)
    excavationStore.setField('updateExcavationVideos', true)
  } catch (e) {
    console.log(e)
    $notify({
      message: `Произошла ошибка при удалении фотографий. ${e}`,
      type: 'error'
    })
  }
}

const isWarning = ref(false)

const handleError = (val) => {
  isWarning.value = val
}

const rules = computed(() => {
  return {
    ...titleValidator('Номер'),
    h_plan: SNumberInputValidator('Глубина (план)'),
    abs: betweenValueValidator('Абсолютная отметка'),
    abs_plan: betweenValueValidator('Абсолютная отметка (план)'),
    lat_plan: SNumberInputValidator('Широта (план)'),
    lon_plan: SNumberInputValidator('Долгота (план)'),
    lat: SNumberInputValidator('Широта'),
    lon: SNumberInputValidator('Долгота'),
    $validationGroups: {
      basic: ['title', 'h_plan'],
      location: ['abs', 'abs_plan', 'lat_plan', 'lon_plan', 'lat', 'lon']
    }
  }
})

const v$ = useVuelidate(rules, excavationDataTemplate)

watch(
  () => v$.value.$errors,
  (newValue) => {
    if (newValue.length === 0) {
      isWarning.value = false
      return
    }

    isWarning.value = true
  }
)

onMounted(() => {
  init()
})
</script>

<template>
  <s-modal
    :title="title"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    :confirm-on-cancel="true"
    :confirm-condition="hasChanges"
    @close="toggleModal"
  >
    <div v-if="!loading" class="excavation-editor">
      <s-collapse v-if="ready" class="background">
        <collapse-item
          :item="initBasicItem"
          :title="initBasicItem.title"
          :source="excavationDataTemplate"
          :services="services"
          :warning="checkIsWarning(initBasicItem.fields)"
          :required="requiredFields"
          :active-id="currentOpenedItemId"
          :is-create="!!!excavation"
          @change="changeActiveCollapse"
          @has-error="handleError"
          :vuelidate="v$"
        />
        <s-location-editor
          @has-error="handleError"
          :source="excavationDataTemplate"
          :warning="checkIsWarning(!excavation ? locationFields : locationFactFields)"
          :required="requiredFields"
          :locationFields="locationFields"
          :locationFactFields="locationFactFields"
          :new-item="!excavation"
          :item="excavation"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
          :vuelidate="v$"
        />
        <collapse-item
          v-for="listItem in fieldsList"
          :key="listItem.id"
          :item="listItem"
          :source="excavationDataTemplate"
          :services="services"
          :warning="checkIsWarning(listItem.fields)"
          :required="requiredFields"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
        />
        <excavation-editor-image
          v-if="excavationDataTemplate?.id"
          :loading="loading"
          :files="files"
          :images="images"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
        />
        <excavation-editor-videos
          v-if="mainStore.isOnline"
          :loading="loading"
          :files="videoFiles"
          :videos="videos"
          :active-id="currentOpenedItemId"
          @change="changeActiveCollapse"
        />
      </s-collapse>
    </div>
    <block-loader title="Загрузка скважины..." v-else />
    <template #footer>
      <div class="excavation-editor-footer">
        <s-button
          icon="trash-can"
          v-if="excavation"
          simple
          icon-color="var(--error)"
          @click="deleteHandlerLocal"
        />
        <s-button
          type="success"
          stretch
          :loading="loading"
          :disabled="loading || disabled.length || isWarning"
          @click="createHandler"
        >
          Сохранить
        </s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.excavation-editor {
  display: grid;
  align-content: start;
  height: 100%;
  overflow: auto;
  padding-right: 0.5rem;

  &-footer {
    display: flex;
    gap: 1rem;
  }
}
</style>
