<script setup>
import { computed } from 'vue'
import { useMainStore } from '@/stores'
import { getImageSrc } from '@/utils'

const mainStore = useMainStore()

const logotypeSource = computed(() => {
  const type = mainStore.theme === 'dark-theme' ? 'dark' : 'light'

  return getImageSrc(`assets/images/logo/soilbox_logotype_${type}_default.png`)
})
</script>

<template>
  <a class="soilbox-logotype" href="https://soilbox.app/" target="_blank">
    <img alt="Logotype" :src="logotypeSource" />
  </a>
</template>

<style lang="scss">
.soilbox-logotype {
  font-size: 0;

  img {
    width: auto;
    height: 24px;
  }
}
</style>
