<script setup>
import { ref, computed, watch } from 'vue'
import { parseImage, loadImage } from './helpers'
import { getValidFiles } from '@/utils'
import WikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'
import AttachmentsItem from './s-attachments-item.vue'
import SAttachmentsGallery from './s-attachments-gallery.vue'

const props = defineProps({
  source: {
    type: Array,
    required: true
  },
  title: {
    type: String,
    default: 'Фотографии'
  },
  noTitle: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  hideList: {
    type: Boolean,
    default: false
  },
  acceptedTypes: {
    type: Array,
    default: () => []
  },
  label: {
    type: String,
    default: 'Загрузка фото'
  },
  max: {
    type: Number,
    default: null
  },
  images: {
    type: Array,
    default: () => []
  },
  additionalText: {
    type: String,
    default: null
  },
  wikiTip: {
    type: Object,
    default: null
  }
})

const emits = defineEmits(['change'])

const loading = ref(false)
const showGallery = ref(false)
const blobImages = ref({})
const tempList = ref([])
const initSource = ref(props.source || [])
const initImages = ref(props.images || [])

const allImages = computed(() => {
  if (!props.multiple) {
    return initSource.value?.length ? initSource.value : initImages.value
  }

  return [...initSource.value, ...initImages.value] || []
})

const hasFiles = computed(() => !!allImages.value.length)

const changeState = computed(() => !props.multiple && hasFiles.value)

const handleDisabled = computed(() => {
  if (props.max && allImages.value.length >= props.max) {
    return true
  }
  if (changeState.value) return false

  return hasFiles.value && !props.multiple
})

const buttonName = computed(() => (changeState.value ? 'Изменить фото' : 'Добавить фото'))

watch(
  () => props.source,
  (val) => {
    if (!val?.length) {
      tempList.value = []
    }
  },
  { immediate: true, deep: true }
)

const uploadRef = ref(null)

const openUploader = () => {
  if (props.disabled || handleDisabled.value) return

  uploadRef.value.click()
}

const handleFiles = async (_, update = false) => {
  if (!uploadRef.value) return

  emits('change')
  loading.value = true

  const { files } = uploadRef.value
  const fileList = [...files]

  const validFiles = getValidFiles(fileList, 'image')

  if (validFiles.length === 0 && !update) {
    loading.value = false
    return
  }

  if (!update) {
    const edittedFiles = fileList.map(
      (file) => new File([file], `${Date.now()}-${file.name}`, { type: file.type })
    )

    const dt = new DataTransfer()

    const allFiles = props.multiple ? [...tempList.value, ...edittedFiles] : [...edittedFiles]

    tempList.value = allFiles.filter((f, i, a) => {
      return a.findIndex((v) => v.name === f.name) === i
    })

    if (props.max && tempList.value?.length > props.max) {
      tempList.value = tempList.value.filter((_, i) => i + 1 <= props.max)
    }
    tempList.value.forEach((file) => dt.items.add(file))
    uploadRef.value.files = dt.files
  } else {
    tempList.value = fileList
  }
  const newSource = []

  await Promise.all(
    tempList.value.map(async (e) => {
      const parsedImage = await parseImage(e)

      newSource.push({
        name: e.name,
        file: parsedImage
      })
    })
  )
  newSource.forEach((n) => {
    const index = initSource.value.findIndex((is) => is.name === n.name)

    if (index === -1) {
      initSource.value.push(n)
    }
  })
  initSource.value.forEach((n, i) => {
    const index = newSource.findIndex((is) => is.name === n.name)

    if (index === -1) {
      initSource.value.splice(i, 1)
    }
  })
  loading.value = false
}

const remove = (file) => {
  emits('change')
  if (file.id) {
    const index = initImages.value.findIndex((f) => f.id === file.id)
    initImages.value.splice(index, 1)
  } else {
    const index = tempList.value.findIndex((f) => f.name === file.name)
    tempList.value.splice(index, 1)

    const dt = new DataTransfer()
    tempList.value.forEach((f) => dt.items.add(f))
    uploadRef.value.files = dt.files

    handleFiles('', true)
  }
}

const loadingGalleryImages = ref(false)
const fullSizeImages = ref([])

const openGallery = async () => {
  showGallery.value = !showGallery.value
  try {
    loadingGalleryImages.value = true
    fullSizeImages.value = await Promise.all(
      allImages.value.map((item) => {
        return loadImage(item, blobImages)
      })
    ).then((results) => results.filter((item) => item))
  } catch (e) {
    console.log(e)
  } finally {
    loadingGalleryImages.value = false
  }
}
</script>

<template>
  <div v-loading="loading" class="attachments">
    <div class="attachments-header">
      <s-text v-if="!noTitle">
        {{ title }}
      </s-text>
      <wiki-tip v-if="wikiTip" :data="wikiTip">
        <s-icon name="fa-circle-question" :color="`var(--${wikiTip.color})`" />
      </wiki-tip>
    </div>
    <div v-if="hasFiles" :class="['attachments-list', { multiple }]">
      <attachments-item
        v-for="(file, i) in allImages"
        :key="i"
        :file="file"
        :single="!multiple"
        @remove-image="remove"
        @click="openGallery"
      />
    </div>
    <div class="attachments-uploader" @click.stop="openUploader">
      <input
        id="attachments-support"
        ref="uploadRef"
        type="file"
        accept="image/*"
        :multiple="multiple"
        @change="handleFiles"
      />
      <s-button
        v-if="!hasFiles"
        class="attachments-button"
        stretch
        :disabled="disabled || handleDisabled"
      >
        <s-div>
          <s-title type="small">
            {{ label }}
          </s-title>
          <div class="attachments-button__text">
            <s-text type="secondary" wrap center>
              Воспользуйтесь камерой или добавьте изображение из галереи.
            </s-text>
            <s-text v-if="additionalText" type="secondary" wrap center>
              {{ additionalText }}
            </s-text>
          </div>
        </s-div>
      </s-button>
      <s-button v-else stretch :disabled="disabled || handleDisabled">
        {{ buttonName }}
      </s-button>
    </div>
    <s-attachments-gallery
      :isVisible="showGallery"
      :thumbnails="true"
      :images="fullSizeImages"
      :thumbnailsSlidesPerView="10"
      @close="showGallery = false"
      :loading="loadingGalleryImages"
    />
  </div>
</template>

<style lang="scss">
.attachments {
  display: grid;
  grid-gap: 0.5rem;
  border-radius: var(--border-radius);

  &-modal__body {
    display: grid;
    height: 100%;
    grid-gap: 1rem;
    align-content: center;
    justify-items: center;
  }

  &-header {
    display: flex;
    justify-content: space-between;

    &:empty {
      display: none;
    }
  }

  &-button {
    padding: 1rem !important;
    height: auto !important;

    &__text {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  &-list {
    display: grid;
    grid-gap: 0.5rem;

    &.multiple {
      justify-content: start;
      grid-auto-flow: column;
      overflow: auto;
    }
  }

  &-uploader {
    &.disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    & > input {
      display: none;
    }
  }

  &-preview {
    position: relative;
    min-height: 64px;
    min-width: 64px;
    border: 1px solid var(--main-bg);
    border-radius: var(--border-radius);
    overflow: hidden;
    text-align: center;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
      max-height: 100%;
    }
  }
}

.s-modal__body:has(.attachments-modal__body) {
  overflow-x: hidden;
  display: block;
}
</style>
