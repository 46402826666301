<script setup>
import { nextTick, onMounted, ref, watch } from 'vue'
import 'swiper/swiper-bundle.css'
import { register } from 'swiper/element/bundle'
import { Thumbs, Zoom, Keyboard, Mousewheel } from 'swiper/modules'
register({ Thumbs, Zoom, Keyboard, Mousewheel })
import debounce from 'lodash.debounce'

import 'swiper/element/css/thumbs'
import 'swiper/element/css/zoom'
import 'swiper/css/keyboard'
import 'swiper/css/mousewheel'

import SVideoItem from '../s-attachments-video/s-video-item.vue'
import { useAuthStore } from '@/stores'

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  type: {
    type: String,
    default: 'images'
  },
  spaceBetween: {
    type: Number,
    default: 10
  },
  slidesPerView: {
    type: Number,
    default: 1
  },
  centeredSlides: {
    type: Boolean,
    default: true
  },
  navigation: {
    type: Boolean,
    default: false
  },
  thumbnails: {
    type: Boolean,
    default: false
  },
  thumbnailsSlidesPerView: {
    type: [Number, String],
    default: 'auto'
  },
  thumbnailsWidth: {
    type: [Number, String],
    default: 'auto'
  },
  zoom: {
    type: Boolean,
    default: true
  },
  initialSlide: {
    type: Number,
    default: 0
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['slide-change', 'video-ready'])

const authStore = useAuthStore()

const thumbs = ref(null)
const mainSwiper = ref(null)

const initThumbs = () => {
  const swiperParams = {
    touchRatio: 0.2,
    spaceBetween: props.spaceBetween,
    initialSlide: props.initialSlide,
    breakpoints: {
      320: {
        slidesPerView: 4
      },
      480: {
        slidesPerView: 6
      },
      640: {
        slidesPerView: 8
      },
      960: {
        slidesPerView: 14
      }
    },
    slideToClickedSlide: true
  }

  if (thumbs.value && swiperParams) {
    Object.assign(thumbs.value, swiperParams)
    thumbs.value.initialize()
  }
}

const downloadCurrentImage = () => {
  const swiper = mainSwiper.value.swiper
  const activeIndex = swiper.activeIndex
  const link = document.createElement('a')
  link.href = props.items[activeIndex]
  link.download = `Изображение ${activeIndex + 1}.jpg`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const initThumbsDebounced = debounce(() => {
  nextTick(() => {
    initThumbs()
    mainSwiper.value?.swiper.update()
  })
}, 500)

watch(
  () => props.items,
  () => {
    if (props.thumbnails) {
      initThumbsDebounced()
    }
  },
  { deep: true }
)

const rotateValue = ref(0)

const rotateImage = () => {
  rotateValue.value = (rotateValue.value - 90) % 360
}

let currentVideoIndex = ref(null)
const currentSlideIndex = ref(null)

const slideChange = () => {
  if (!mainSwiper.value) return
  currentSlideIndex.value = mainSwiper.value.swiper.activeIndex

  if (props.type === 'images') {
    const activeIndex = mainSwiper.value.swiper.activeIndex
    emits('slide-change', activeIndex)
  }

  currentVideoIndex.value = mainSwiper.value.swiper.previousIndex
}

const clearVideoIndex = () => {
  currentVideoIndex.value = null
}

const getThumbnail = (item) => {
  return `${item.thumbnail_url}?access_token=${authStore.userToken}`
}

onMounted(() => {
  currentSlideIndex.value = mainSwiper.value.swiper.activeIndex

  if (props.thumbnails) {
    initThumbs()
  }

  emits('slide-change', props.initialSlide)
})
</script>

<template>
  <div class="s-swiper" v-loading="loading">
    <swiper-container
      @swiperslidechange="slideChange"
      ref="mainSwiper"
      :zoom="zoom"
      :keyboard="true"
      :mousewheel="true"
      :slides-per-view="slidesPerView"
      :centeredSlides="centeredSlides"
      thumbs-swiper=".s-swiper-thumbs"
      :navigation="navigation"
      :spaceBetween="spaceBetween"
      class="s-swiper-container"
      :injectStyles="[
        ':host .swiper-button-prev, :host .swiper-button-next { color: var(--caption); }',
        ':host .swiper-wrapper { align-items: center; }'
      ]"
      :initialSlide="initialSlide"
    >
      <swiper-slide v-for="(item, index) in items" :key="item">
        <div v-if="type === 'images'" class="swiper-zoom-container" :data-index="index">
          <div
            class="rotate-wrapper"
            :style="{
              transform: `rotate(${rotateValue}deg)`
            }"
          >
            <img :src="item" alt="" />
          </div>
        </div>
        <div v-else class="swiper-zoom-container swiper-no-swiping" :data-index="index">
          <s-video-item
            @clear-video-index="clearVideoIndex"
            :current-slide-index="currentSlideIndex"
            :slide-index="currentVideoIndex"
            :index="index"
            :item="item"
            @video-ready="emits('video-ready')"
          />
        </div>
      </swiper-slide>
    </swiper-container>

    <div class="controls" v-if="type === 'images'">
      <s-button @click="downloadCurrentImage" :icon="'download'" simple />
      <s-button @click="rotateImage" :icon="'rotate-left'" simple />
    </div>

    <slot />

    <swiper-container ref="thumbs" :init="false" class="s-swiper-thumbs">
      <swiper-slide
        v-loading="!image"
        class="swiper-slide"
        v-for="(image, index) in items"
        :key="`thumb-${index}`"
      >
        <img
          v-if="image"
          :src="image?.thumbnail_url ? getThumbnail(image) : image"
          alt="Thumbnail"
        />
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<style lang="scss">
.s-swiper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  max-height: 100%;
  height: 100%;
  justify-content: space-between;

  &-container {
    width: 100%;
    height: 100%;
    max-height: calc(100% - 160px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-thumbs {
    max-width: 100%;
    // height: 64px;

    & swiper-slide {
      min-width: 64px;
      // aspect-ratio: 1 / 1;
      // background-color: var(--bg);
      // border-radius: 10px;
      // border: 1px solid var(--main-bg);
      display: flex;
      justify-content: center;

      &.swiper-slide-thumb-active {
        & img {
          border: 1px solid var(--primary) !important;
        }
      }
    }

    & img {
      width: 64px;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      min-height: 64px;
      min-width: 64px;
      background-color: var(--bg);
      border-radius: 10px;
      border: 1px solid var(--main-bg);
    }
  }

  & .swiper-zoom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    & img {
      object-fit: contain;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      border-radius: 8px;
      border: 1px solid var(--main-bg);
    }
  }
}

.controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}

.swiper-zoom-container {
  .rotate-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 300ms;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
</style>
