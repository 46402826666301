import { closeEditorsOnScrollHorizontally } from '../../helpers'
import contextMenuActions from '../context-menu/context-menu.js'

export const samplesColumns = [
  {
    id: 'general',
    label: 'Общее',
    attributes: [
      {
        readOnly: true,
        type: 'text',
        data: 'id',
        key: 'id',
        title: 'Номер пробы',
        width: '140px'
      },

      {
        data: 'excavation_title',
        key: 'excavation_title',
        type: 'text',
        readOnly: true,
        title: 'Скважина',
        width: '140px'
      },
      {
        type: 'numeric',
        data: 'selection_from_d',
        key: 'selection_from_d',
        title: 'От, м',
        units: 'м',
        width: '80px'
      },
      {
        type: 'numeric',
        data: 'selection_to_d',
        key: 'selection_to_d',
        title: 'До, м',
        units: 'м',
        width: '80px'
      },
      {
        type: 'dropdown',
        data: 'type',
        key: 'type',
        columnSorting: { headerAction: false },
        title: 'Тип пробы',
        source: null
      },
      {
        type: 'text',
        data: 'date_front',
        key: 'date_front',
        dateFormat: 'YYYY-MM-DD',
        readOnly: true,
        correctFormat: true,
        datePickerConfig: {
          i18n: {
            previousMonth: 'Прошлый месяц',
            nextMonth: 'След месяц',
            months: [
              'Январь',
              'Февраль',
              'Март',
              'Апрель',
              'Май',
              'Июнь',
              'Июль',
              'Август',
              'Сентябрь',
              'Октябрь',
              'Ноябрь',
              'Декабрь'
            ],
            weekdays: [
              'Воскресенье',
              'Понедельник',
              'Вторник',
              'Среда',
              'Четверг',
              'Пятница',
              'Суббота'
            ],
            weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
          }
        },
        title: 'Дата отбора',
        width: '120px'
      },
      {
        type: 'numeric',
        readOnly: true,
        data: 'images',
        key: 'images',
        columnSorting: { headerAction: false },
        title: 'Фотографии',
        width: '120px'
      },
      {
        type: 'text',
        data: 'comments',
        key: 'comments',
        title: 'Комментарий',
        width: '350px'
      }
    ]
  },
  {
    id: 'sample-description',
    label: 'Описание грунта',
    attributes: [
      {
        type: 'text',
        data: 'soil_description',
        key: 'soil_description',
        title: 'Полное описание',
        readOnly: true,
        width: 520
      },
      {
        type: 'text',
        data: 'soil_short_description',
        key: 'soil_short_description',
        title: 'Сокращенное описание',
        readOnly: true
      },
      {
        type: 'text',
        data: 'soil_state_text',
        key: 'soil_state_text',
        title: 'Состояние ',
        readOnly: true
      },
      {
        type: 'text',
        data: 'soil_state_pfrost_text',
        key: 'soil_state_pfrost_text',
        title: 'Состояние мерзлого грунта ',
        readOnly: true
      },
      {
        type: 'text',
        data: 'soil_water_saturation_text',
        key: 'soil_water_saturation_text',
        title: 'Консистенция/водонасыщение',
        readOnly: true
      },
      {
        type: 'text',
        data: 'soil_ice_content_text',
        key: 'soil_ice_content_text',
        title: 'Льдистость',
        readOnly: true
      },
      {
        type: 'text',
        data: 'soil_density_text',
        key: 'soil_density_text',
        title: 'Плотность',
        readOnly: true
      },
      {
        type: 'text',
        data: 'soil_alteration_form_organic_text',
        key: 'soil_alteration_form_organic_text',
        title: 'Содержание ОВ',
        readOnly: true
      },
      {
        type: 'text',
        data: 'soil_alteration_form_carbonate_text',
        key: 'soil_alteration_form_carbonate_text',
        title: 'Карбонатность',
        readOnly: true
      },
      {
        type: 'text',
        data: 'soil_alteration_form_ferrum_text',
        key: 'soil_alteration_form_ferrum_text',
        title: 'Ожелезненность',
        readOnly: true
      },
      {
        type: 'text',
        data: 'soil_alteration_minor_mud_text',
        key: 'soil_alteration_minor_mud_text',
        title: 'Загрязненность',
        readOnly: true
      }
    ]
  }
]

export const initialSamplesColumns = [
  'id',
  'excavation_title',
  'selection_from_d',
  'selection_to_d',
  'type',
  'date_front',
  'images',
  'comments',
  'soil_description',
  'soil_short_description',
  'soil_state_text',
  'soil_state_pfrost_text',
  'soil_water_saturation_text',
  'soil_ice_content_text',
  'soil_density_text',
  'soil_alteration_form_organic_text',
  'soil_alteration_form_carbonate_text',
  'soil_alteration_form_ferrum_text',
  'soil_alteration_minor_mud_text'
]

export const samplesTypesDictionary = {
  'Нарушенная структура': 'Нар. стр-ра'
}

export const samplesListTips = {
  title: 'Список проб пуст',
  description: 'В данном проекте отсутствуют добавленные пробы'
}

export const sheetsSettings = {
  filters: true,
  columnSorting: true,
  selectionMode: 'multiple',
  height: 'calc(100vh - 360px)',
  outsideClickDeselects: false,
  copyPaste: {
    copyColumnHeaders: false
  },
  stretchH: 'all',
  contextMenu: contextMenuActions,
  search: {
    searchResultClass: 'searched-values'
  },
  dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
  rowHeaders: true,
  afterScrollHorizontally: function () {
    closeEditorsOnScrollHorizontally(this)
  }
}
