<script setup>
import { ref, watch } from 'vue'
import SSwiper from '@/components/s-swiper/s-swiper.vue'

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  },
  thumbnails: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array,
    default: null
  },
  thumbnailsSlidesPerView: {
    type: [Number, String],
    default: 'auto'
  },
  loading: {
    type: Boolean,
    default: false
  },
  initialSlide: {
    type: Number,
    default: 0
  }
})

const emits = defineEmits(['close', 'video-ready'])

const localImages = ref(props.items)

watch(
  () => props.items,
  () => {
    localImages.value = props.items
  },
  { deep: true }
)
</script>

<template>
  <s-modal title="Видео" :show="isVisible" fullscreen @close="emits('close')">
    <s-swiper
      :thumbnailsSlidesPerView="thumbnailsSlidesPerView"
      :thumbnails="thumbnails"
      navigation
      :initialSlide="initialSlide"
      :loading="loading"
      :items="localImages"
      type="video"
      @video-ready="emits('video-ready')"
    >
    </s-swiper>
  </s-modal>
</template>
