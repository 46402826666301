import cloneDeep from 'lodash.clonedeep'
import { getExcavationsChanges, getUuid } from '@/utils'
import { useMainStore, useAuthStore } from '@/stores'
import { basicItem, fieldsList } from '../configs'
import { useRitmDate, useRequests } from '@/composables'
import db from '@/libs/db'
import appGlobalProperties from '@/main'

const parseDateFields = (data) => {
  const rDate = useRitmDate()

  data.date_front = rDate(new Date()).format('iso')

  if (data.date_from) {
    data.date_from = rDate(data.date_from).format('YYYY-MM-DD')
  }

  if (data.date_to) {
    data.date_to = rDate(data.date_to).format('YYYY-MM-DD')
  }
}

export const createExcavation = async (objectId, excavation, loading, callback, filter) => {
  const mainStore = useMainStore()
  const { postRequest } = useRequests()

  loading = true
  try {
    let data = cloneDeep(excavation)
    data.uuid = getUuid()
    parseDateFields(data)

    if (mainStore.isOnline && !mainStore.noSyncMode) {
      const response = await postRequest(`objects/${objectId}/excavations/`, data)
      if (!response) return
      data = response

      data.server_id = response?.id
      delete data.id
    }
    data.object_id = objectId

    const id = await db.addObject('excavations', data, filter)

    if (!mainStore.isOnline || mainStore.noSyncMode) {
      await db.created.add({
        table: 'excavations',
        date: new Date(),
        item_id: id,
        uuid: data.uuid
      })
      getExcavationsChanges()
    }

    const title = 'Создание'
    const message = `Выработка "${data.title}" успешно создана`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'success'
    })
    if (callback) {
      callback()
    }
  } catch (error) {
    const { response } = error
    if (response?.status === 400) {
      const { data } = response
      handleValidationError(data)
    } else {
      appGlobalProperties.$notify({
        title: 'Создание',
        message: `Ошибка при создании выработки: ${error}`,
        type: 'error',
        duration: 3000
      })
    }
  } finally {
    loading = false
  }
}

export const editExcavation = async (excavation, loading, callback, filter) => {
  const mainStore = useMainStore()
  const authStore = useAuthStore()
  const { putRequest } = useRequests()

  loading = true
  try {
    excavation.date_front = new Date()

    if (!excavation.geologist) {
      excavation.geologist = authStore.userId
    }

    const toServer = Object.assign({}, excavation)
    const toIdb = Object.assign({}, excavation)

    if (mainStore.isOnline && !mainStore.noSyncMode) {
      toServer.id = toServer.server_id
      delete toServer.object_id
      toServer.type = null
      await putRequest(`excavations/${toServer.id}/`, toServer)
    }

    await db.updateObject('excavations', toIdb, filter)

    if ((!mainStore.isOnline || mainStore.noSyncMode) && excavation.server_id) {
      const updatedItems = await db.updated.where('table').equals('excavations').toArray()
      const isExist = !!updatedItems.find((e) => e.item_id === excavation.server_id)

      if (!isExist) {
        await db.updated.put({
          table: 'excavations',
          item_id: excavation.server_id,
          date: new Date()
        })
        getExcavationsChanges()
      }
    }

    const title = 'Редактирование'
    const message = `Выработка "${toIdb.title}" успешно отредактирована`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'success'
    })
    if (callback) {
      callback(true)
    }
  } catch (error) {
    const { response } = error
    if (response?.status === 400) {
      const { data } = response
      handleValidationError(data)
    } else {
      appGlobalProperties.$notify({
        title: 'Создание',
        message: `Ошибка при создании выработки: ${error}`,
        type: 'error',
        duration: 3000
      })
    }
  } finally {
    loading = false
  }
}

const handleValidationError = (errorData) => {
  const allFields = [...basicItem.fields, ...fieldsList.flatMap((item) => item?.fields || [])]
  let errorString = ''

  for (const [key, value] of Object.entries(errorData)) {
    const errorField = allFields.find((field) => field.title === key)

    if (errorField) {
      errorString += `${errorField.label ? errorField.label : key} - ${value}`
    }
  }

  appGlobalProperties.$notify({
    title: 'Создание',
    message: `Ошибка при создании выработки. ${errorString}`,
    type: 'error',
    duration: 5000
  })
}
