<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useExcavationStore, useReconStore } from '@/stores'

const excavationStore = useExcavationStore()
const reconStore = useReconStore()

const isShow = ref(true)

const imageLoading = computed(() => {
  return [...excavationStore.imageLoading, ...reconStore.imageLoading] || []
})

const fullLength = computed(() => {
  return imageLoading.value.length || 0
})

const uploadedLength = computed(() => {
  return imageLoading.value?.filter((l) => l.uploaded)?.length || 0
})

const videoLoading = computed(() => {
  return [...excavationStore.videoLoading] || []
})

const fullVideoLength = computed(() => {
  return videoLoading.value.length || 0
})

const videoUploadedLength = computed(() => {
  return videoLoading.value?.filter((l) => l.uploaded)?.length || 0
})

const description = computed(() => {
  if (excavationStore.loadingType === 'video') {
    return fullVideoLength.value
      ? `Загрузка видео на сервер... (${videoUploadedLength.value} / ${videoLoading.value.length})`
      : 'Загрузка видео завершена'
  }

  return fullLength.value
    ? `Загрузка фотографий на сервер... (${uploadedLength.value} / ${fullLength.value})`
    : 'Загрузка фото завершена'
})

const type = computed(() => {
  if (excavationStore.loadingType === 'video') {
    return fullVideoLength.value === videoUploadedLength.value ? 'success' : 'loading'
  }

  return fullLength.value === uploadedLength.value ? 'success' : 'loading'
})

watch(
  () => imageLoading.value,
  (newValue) => {
    if (!newValue.length) return
    isShow.value = !!imageLoading.value?.length

    if (fullLength.value === uploadedLength.value) {
      excavationStore.setField('imageLoading', [])
      reconStore.setField('imageLoading', [])

      setTimeout(() => {
        if (videoLoading.value.length) return
        isShow.value = false
      }, 512)
    }
  },
  {
    deep: true
  }
)

watch(
  () => videoLoading.value,
  (newValue) => {
    if (!newValue.length) return
    isShow.value = !!videoLoading.value?.length

    if (fullVideoLength.value === videoUploadedLength.value) {
      excavationStore.setField('videoLoading', [])
      setTimeout(() => {
        isShow.value = false
        excavationStore.setField('loadingType', null)
      }, 512)
    }
  },
  {
    deep: true
  }
)

onMounted(() => {
  isShow.value = !!imageLoading.value?.length || !!videoLoading.value?.length
})
</script>

<template>
  <div v-if="isShow" :class="[`mobile-image-loading ${type}`]">
    {{ description }}
  </div>
</template>

<style lang="scss">
@keyframes move-it {
  0% {
    background-position: initial;
  }
  100% {
    background-position: 100px 0px;
  }
}
.mobile-image-loading {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  width: 100%;
  height: 1.25rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: var(--primary);

  &.loading {
    background: repeating-linear-gradient(
      45deg,
      #2792ed30,
      #2792ed30 15%,
      #2792ed10 15%,
      #2792ed10 50%
    );
    background-size: 100px 100px;
    animation: move-it 5s linear infinite;
  }

  &.success {
    color: var(--success);
    background-color: var(--success--select);
  }
}
</style>
