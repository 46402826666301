import appGlobalProperties from '@/main'

export const checkIsWrongExtension = (acceptedExtension, extension) => {
  return acceptedExtension.every((acceptedType) => {
    const acceptedExtension = acceptedType.split('.').pop().toLowerCase()
    return acceptedExtension !== extension.toLowerCase()
  })
}

export const showErrorNotify = (message) => {
  appGlobalProperties.$notify({
    message,
    type: 'error',
    duration: 5000
  })
}

export const getValidFiles = (files, validMimeType) => {
  const result = files.filter((file) => {
    if (file.type.startsWith(`${validMimeType}/`)) {
      return file
    }

    const message = `Недопустимый формат файла. ${file.name}`
    showErrorNotify(message)
  })

  return result
}
