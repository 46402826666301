<script setup>
import { computed, ref, watch } from 'vue'
import SSwiper from '@/components/s-swiper/s-swiper.vue'
import SampleType from '@/modules/app/data/excavation/components/excavation-samples/components/sample-type.vue'

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false
  },
  thumbnails: {
    type: Boolean,
    default: false
  },
  images: {
    type: Array,
    default: null
  },
  thumbnailsSlidesPerView: {
    type: [Number, String],
    default: 'auto'
  },
  source: {
    type: Object,
    default: () => null
  },
  sourceType: {
    type: String,
    validator: (value) => ['sample', 'corebox'].includes(value)
  },
  loading: {
    type: Boolean,
    default: false
  },
  initialSlide: {
    type: Number,
    default: 1
  },
  galleryMode: {
    type: Boolean,
    default: false
  },
  galleryModeItems: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['close'])

const activeCorebox = ref(props.source)
const localImages = ref(props.images)

watch(
  () => props.images,
  () => {
    localImages.value = props.images
  },
  { deep: true }
)

const getTitle = computed(() => {
  if (props.sourceType === 'sample') {
    const from = props.source?.selection_from_d?.toFixed(1)
    const to = props.source?.selection_to_d?.toFixed(1)
    return `Проба ${from} - ${to} м`
  }

  const from = activeCorebox.value?.foot_d0?.toFixed(1)
  const to = activeCorebox.value?.foot_d1?.toFixed(1)
  return `Керн ${from} - ${to} м`
})

const slideChange = (activeIndex) => {
  activeCorebox.value = props.galleryModeItems[activeIndex]
}
</script>

<template>
  <s-modal title="Изображения" :show="isVisible" fullscreen @close="emits('close')">
    <s-swiper
      :thumbnailsSlidesPerView="thumbnailsSlidesPerView"
      :thumbnails="thumbnails"
      navigation
      :initialSlide="initialSlide"
      :loading="loading"
      :items="localImages"
      @slide-change="slideChange"
    >
      <div v-if="source" class="attachments-gallery-modal__info">
        <s-text>
          {{ getTitle }}
        </s-text>
        <sample-type v-if="sourceType === 'sample'" :sample="source" />
      </div>
    </s-swiper>
  </s-modal>
</template>
