<script setup>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { useAuthStore } from '@/stores'
import { useMq } from 'vue3-mq'
import { templateRef } from '@vueuse/core'
import { computed, ref, watch } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  index: {
    type: Number
  },
  slideIndex: {
    type: Number,
    default: null
  },
  currentSlideIndex: {
    type: Number,
    default: 0
  }
})

const emits = defineEmits(['clear-video-index', 'video-ready'])

const authStore = useAuthStore()

watch(
  () => props.slideIndex,
  (newValue) => {
    if (newValue === props.index) {
      pauseVideo()
      emits('clear-video-index')
    }
  }
)

watch(
  () => props.currentSlideIndex,
  () => {
    if (props.currentSlideIndex === props.index) {
      initPlayer()
    }
  }
)

const mq = useMq()

const playerRef = templateRef('playerRef')

const player = ref(null)

const pauseVideo = () => {
  player.value?.pause()
}

const initPlayer = () => {
  if (!player.value) {
    player.value = videojs(
      playerRef.value,
      {
        controls: true,
        responsive: mq.current === 'lg' ? false : true,
        fluid: mq.current === 'lg' ? false : true,
        disablePictureInPicture: true,
        controlBar: {
          pictureInPictureToggle: false
        },
        sources: [
          {
            src: edittedVideoItems.value,
            type: 'video/mp4'
          }
        ]
      },
      function () {
        this.volume(0.5)
      }
    )

    player.value.on('canplay', () => {
      emits('video-ready')
    })
  }
}

const edittedVideoItems = computed(() => {
  return `${props.item.stream_url}?access_token=${authStore.userToken}`
})
</script>

<template>
  <div class="s-video">
    <video ref="playerRef" preload="auto" class="video-js"></video>
  </div>
</template>

<style lang="scss">
.s-video {
  position: relative;
  min-height: 100%;
  height: 100%;
  width: 100%;
  max-width: 100%;

  & .video-js {
    height: 100% !important;
    max-height: 100%;
    width: 100%;
  }
}

.vjs-icon-play,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder,
.vjs-icon-fullscreen-enter,
.video-js .vjs-fullscreen-control .vjs-icon-placeholder,
.vjs-icon-volume-high,
.video-js .vjs-mute-control .vjs-icon-placeholder {
  font-family: VideoJS !important;
  font-weight: normal;
  font-style: normal;
}
</style>
