<script setup>
import { computed, ref } from 'vue'
import DataStats from './components/data-stats.vue'
import ExportDataModal from './components/export-data-modal.vue'

const props = defineProps({
  dataType: {
    type: String,
    default: 'excavations'
  }
})

const isOpened = ref(false)

const type = computed(() => {
  return props.dataType === 'excavations' ? 'скважины' : 'точки наблюдения'
})
</script>

<template>
  <div class="export-data">
    <div class="export-data__header" @click="isOpened = !isOpened">
      <div class="export-data__header-text">
        <s-title color-type="primary-color" type="small"> Выбрать {{ type }} для экспорта </s-title>
        <s-text type="secondary"> (по умолчанию выгружаются все) </s-text>
      </div>
      <s-icon name="angle-right" color="var(--caption)" />
    </div>
    <data-stats :dataType />

    <export-data-modal :dataType :is-opened="isOpened" @close="isOpened = false" />
  </div>
</template>

<style lang="scss">
.export-data {
  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-text {
      display: flex;
      align-items: center;
      flex: 1;

      .s-text {
        margin-left: 4px;
      }
    }
  }

  &__inner {
    margin-top: 8px;
  }
}

.sm {
  .export-data {
    &__header-text {
      align-items: start;
      flex-direction: column;

      .s-text {
        margin-left: 0;
      }
    }
  }
}
</style>
