export const sandFields = {
  basic: [
    {
      id: 'water_saturation',
      value: 'water_saturation',
      title: 'Укажите степень водонасыщения',
      type: 'soil-water-comp',
      commentField: 'water_saturation_comments',
      commentTitle: 'Комментарий к степени водонасыщения'
    },
    {
      id: 'text_thaw',
      title: 'text_thaw',
      label: 'Укажите текстуру грунта',
      type: 'select',
      service: 'texts_thaw',
      commentField: 'struct_text_comments',
      commentTitle: 'Комментарий к текстуре',
      clearable: true
    },
    {
      id: 'composition',
      title: 'Укажите информацию о зернах',
      type: 'group',
      groups: [
        {
          id: 'homogen',
          title: 'homogen',
          label: 'Однородность',
          type: 'select',
          service: 'homogen'
        },
        {
          id: 'composition_chem',
          title: 'composition_chem',
          label: 'Минеральный состав',
          type: 'select',
          service: 'compositions_chem'
        },
        {
          id: 'debris_round',
          title: 'debris_round',
          label: 'Окатанность',
          type: 'select',
          service: 'debris_rounds'
        },
        {
          id: 'composition_grains',
          title: 'composition_grains',
          label: 'Крупнообломочные примеси',
          type: 'select',
          service: 'compositions_grains'
        }
      ],
      commentField: 'composition_comments',
      commentTitle: 'Комментарий к описанию зерен'
    },
    {
      id: 'density',
      title: 'density',
      label: 'Укажите плотность',
      type: 'select',
      service: 'densities',
      commentField: 'density_comments',
      commentTitle: 'Комментарий к плотности'
    }
  ],
  additional: true,
  inclusions: true,
  interlayers: true
  // additional: [
  //   {
  //     id: 'inclusions',
  //     value: 'inclusions',
  //     title: 'Включения',
  //     type: 'inclusions'
  //   },
  //   {
  //     id: 'interlayers',
  //     value: 'interlayers',
  //     title: 'Прослои',
  //     type: 'interlayers'
  //   },
  //   {
  //     id: 'additional',
  //     title: 'Укажите дополнительную информацию',
  //     type: 'additional',
  //     createTrigger: true
  //   }
  // ]
}
