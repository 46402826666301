import axios from 'axios'
import { errorParser, parseFieldTypes } from '@/utils'
import { BASE_URL } from '@/configs/urls'
import { useAuthStore } from '@/stores'

export function useRequests() {
  const authStore = useAuthStore()
  const getUrn = () => {
    return authStore.isAdmin ? 'api/admin' : 'api'
  }

  const makeRequest = (method, url, data = null, headers = {}) => {
    return new Promise((resolve, reject) => {
      const urn = getUrn()
      if (data) parseFieldTypes(data)

      axios[method](`${BASE_URL}/${urn}/${url}`, data, headers)
        .then((response) => resolve(response.data))
        .catch((error) => {
          errorParser(reject, error)
          throw error
        })
    })
  }

  const getRequest = (url, headers = {}) => makeRequest('get', url, null, headers)
  const postRequest = (url, data, headers = {}) => makeRequest('post', url, data, headers)
  const putRequest = (url, data, headers = {}) => makeRequest('put', url, data, headers)
  const patchRequest = (url, data, headers = {}) => makeRequest('patch', url, data, headers)
  const deleteRequest = (url, headers = {}) => makeRequest('delete', url, null, headers)

  return {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest
  }
}
