<script setup>
defineProps({
  types: {
    type: Array,
    required: true
  }
})

const emits = defineEmits(['select-type'])

const clickHandler = (type) => {
  emits('select-type', type)
}
</script>

<template>
  <ul class="export-types">
    <li v-for="type in types" :key="type.id" class="export-types__item" @click="clickHandler(type)">
      <div class="export-types__icon">
        <s-icon :name="type.icon" size="xl" color="var(--caption)" />
      </div>
      <div class="export-types__info">
        <s-title type="small">{{ type.label }}</s-title>
        <s-text type="secondary">
          {{ type.description }}
        </s-text>
      </div>

      <div v-if="type.beta" class="export-types__item-beta">
        BETA
      </div>
    </li>
  </ul>
</template>

<style lang="scss">
.export-types {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;

  &__item {
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid var(--main-bg);
    border-radius: 8px;
    background-color: var(--bg);
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: var(--primary--hover);
    }

    &-beta {
      padding: 1px 6px;
      border-radius: 8px;
      background: var(--gradient);
      display: inline-block;
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 1;
      font-size: 10px !important;
      color: #fff;
    }
  }

  &__icon {
    margin-right: 12px;
  }
}

.sm {
  .export-types {
    grid-template-columns: 1fr;
  }
}
</style>
