<script setup>
import { ref, watch, onMounted, inject } from 'vue'
import { useAuthStore } from '@/stores'

const props = defineProps({
  file: {
    type: Object,
    required: true
  },
  single: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['remove-image'])

const authStore = useAuthStore()

const $confirm = inject('$confirm')

const loading = ref(false)
const imageLink = ref(null)

watch(
  () => props.file,
  () => {
    prepareLink()
  }
)

const prepareLink = async () => {
  if (props.file.idb) {
    try {
      imageLink.value = await URL.createObjectURL(props.file.image.file)
    } catch (e) {
      console.log(e)
    }
  } else if (props.file?.id) {
    try {
      loading.value = true

      const res = await fetch(`${props.file?.image_url}?access_token=${authStore.userToken}`)
      const blob = await res.blob()

      imageLink.value = URL.createObjectURL(blob)
    } catch (e) {
      return null
    } finally {
      loading.value = false
    }
  } else {
    try {
      imageLink.value = URL.createObjectURL(props.file.file)
    } catch (e) {
      console.log(e)
    }
  }
}

const removeFile = () => {
  const message = 'Вы точно хотите удалить фотографию?'
  const title = 'Удаление фотографии'

  $confirm(message, title, {
    confirmButtonText: 'Удалить',
    cancelButtonText: 'Отменить',
    confirmButtonClass: 'error'
  })
    .then(() => emits('remove-image', props.file))
    .catch(() => {})
}

onMounted(() => {
  prepareLink()
})
</script>

<template>
  <div v-loading="loading" :class="['attachments-item', { single }]">
    <img v-if="imageLink" :src="imageLink" :alt="file.name" />
    <s-button
      class="attachments-item__delete-btn"
      circle
      icon="times"
      size="sm"
      @click.stop="removeFile"
    />
  </div>
</template>

<style lang="scss">
.attachments-item {
  position: relative;
  padding: 0.5rem;
  min-width: 84px;
  cursor: pointer;

  img {
    height: 64px;
    width: 64px;
    object-fit: cover;
    border-radius: var(--border-radius);
  }

  &__delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px !important;
    height: 24px !important;
    min-height: auto !important;
  }

  &.single {
    border-radius: var(--border-radius);
    background-color: var(--main-bg);
    min-height: 200px;

    img {
      width: 100%;
      height: 320px;
      object-fit: contain;
    }

    .attachments-item__delete-btn {
      display: none;
    }
  }
}
</style>
