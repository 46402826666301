import { useSheetsStore, useObjectsStore, useServicesStore } from '@/stores'
import Handsontable from 'handsontable'
import appGlobalProperties from '@/main'
const { DropdownRenderer } = Handsontable.renderers
import { saveDataToLS, capitalizeFirstLetter, decimalDegreesToDMS } from '@/utils'

const relatedColumnsMap = {
  lat_plan_dms: 'lat_plan',
  lon_plan_dms: 'lon_plan',
  lat_dms: 'lat',
  lon_dms: 'lon',
  lat_plan: 'lat_plan_dms',
  lon_plan: 'lon_plan_dms',
  lat: 'lat_dms',
  lon: 'lon_dms'
}

const coordsFieldsTypeFormat = {
  decimals: ['lat_plan', 'lon_plan', 'lat', 'lon'],
  dms: ['lat_plan_dms', 'lon_plan_dms', 'lat_dms', 'lon_dms']
}

const fieldsToCheck = [
  'date_from',
  'lat_plan',
  'lon_plan',
  'bore_master',
  'bore_machine',
  'h',
  'h_plan',
  'diam_1',
  'diam_2',
  'geologist',
  'site',
  'artificial_f',
  'natural_f',
  'geomorph_id',
  'date_to',
  'total_sample_images',
  'total_images',
  'total_corebox_images',
  'total_monolyth'
]

export const toggleFormatCoords = (columnsKeys, formatValue, storeColumnsName, LSName) => {
  const sheetsStore = useSheetsStore()

  let toggledCoordsKeys = columnsKeys.map((key) => {
    if (relatedColumnsMap[key]) {
      let temp
      if (!coordsFieldsTypeFormat[formatValue].includes(key)) {
        temp = relatedColumnsMap[key]
      } else {
        temp = key
      }

      return temp
    }

    return key
  })

  saveDataToLS(LSName, toggledCoordsKeys)
  sheetsStore.setField(LSName, toggledCoordsKeys)
  sheetsStore[storeColumnsName].forEach((col) => {
    col.attributes.forEach((attr) => {
      if (toggledCoordsKeys.includes(attr.key)) {
        attr.checked = true
      } else {
        attr.checked = false
      }
    })
  })
}

export const toggleColumns = (storeColName, columnsKeys, fieldName) => {
  const sheetsStore = useSheetsStore()

  sheetsStore[storeColName].forEach((col) => {
    col.attributes.forEach((attr) => {
      if (attr.checked && !columnsKeys.value.includes(attr.key)) {
        columnsKeys.value.push(attr.key)
      } else if (!attr.checked) {
        columnsKeys.value = columnsKeys.value.filter((key) => key !== attr.data)
      }
    })
  })

  sheetsStore.setField(fieldName, columnsKeys.value)
  saveDataToLS(fieldName, columnsKeys.value)
}

export const updateTableSettings = (table, dataController, data, sourceType) => {
  table.value.updateSettings({
    columns: dataController.value.filteredColumns().map((column) => {
      if (column.data === 'status') {
        return {
          ...column,
          renderer: changeStatusColorsRenderer
        }
      }

      return column
    }),
    cells: changeCellColors(table, sourceType),
    data
  })
}

const statusClasses = {
  ['Не начата']: 'caption',
  ['Вынесена']: 'orange',
  ['Бурение']: 'primary',
  ['Закрыта']: 'success'
}

const changeStatusColorsRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  DropdownRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties])

  const status = instance.getDataAtRowProp(row, 'status')
  td.className = 'htMiddle htCenter'

  if (statusClasses[status]) {
    td.classList.add(statusClasses[status])
  }
}

const changeCellColors = (table, sourceType) => {
  return (row, col) => {
    const cellProperties = {
      className: 'htMiddle htCenter'
    }

    const visualRow = table.value.toVisualRow(row)

    const geologistIndex = table.value.propToCol('geologist')

    if (geologistIndex === col) {
      highlightGeologists(cellProperties, table, visualRow)
    }

    const status = table.value.getDataAtRowProp(visualRow, 'status')
    const highlightCells = isShowHighlight(status, sourceType)

    if (highlightCells) {
      hightlightEmptyDataCells(cellProperties, table, visualRow, col)
    }

    const coords_offsetIndex = table.value.propToCol('coords_offset')
    if (coords_offsetIndex === col) {
      checkAndHighlightOffset(cellProperties, table, visualRow, col)
    }

    return cellProperties
  }
}

const checkAndHighlightOffset = (cellProperties, table, row, col) => {
  const column = table.value.getSettings().columns[col]
  const columnDataKey = column?.data

  const cellValue = table.value.getDataAtRowProp(row, columnDataKey)

  if (cellValue > 20) {
    cellProperties.className += ` warning`
  }
}

const hightlightEmptyDataCells = (cellProperties, table, row, col) => {
  const column = table.value.getSettings().columns[col]
  const columnDataKey = column?.data

  if (fieldsToCheck.includes(columnDataKey)) {
    const cellValue = table.value.getDataAtRowProp(row, columnDataKey)

    if (!cellValue) {
      cellProperties.className += ` warning`
    }
  }
}

const highlightGeologists = (cellProperties, table, row) => {
  const servicesStore = useServicesStore()
  const objectsStore = useObjectsStore()

  const geoName = table.value.getDataAtRowProp(row, 'geologist')
  const geoItem = servicesStore?.users?.find((item) => item.title === geoName)

  if (geoItem && !objectsStore.activeObject?.geologists.includes(geoItem.id)) {
    cellProperties.className += ` invalid`
  }
}

const isShowHighlight = (status, sourceType) => {
  const sheetsStore = useSheetsStore()

  return sheetsStore.isShowHighlight && status === 'Закрыта' && sourceType === 'excavations'
}

export const sources = () => {
  const servicesStore = useServicesStore()
  const objectsStore = useObjectsStore()

  return {
    status: servicesStore.excav?.statuses.map((status) => {
      return {
        title: capitalizeFirstLetter(status.title),
        id: status.id
      }
    }),
    sync_status: [
      {
        title: 'Не начата',
        id: 0
      },
      {
        title: 'В процессе',
        id: 1
      },
      {
        title: 'Завершена',
        id: 2
      }
    ],
    geologist: objectsStore?.activeObject?.actual_geologists?.map((user) => {
      return {
        id: user.id,
        title: user.get_short_name,
        disabled: !user.is_active
      }
    }),
    geomorph_id: servicesStore?.excav?.geomorphies?.map((geo) => {
      return {
        title: geo.title,
        id: geo.id
      }
    }),
    casing: servicesStore?.excav?.casings?.map((casing) => {
      return {
        title: casing.title,
        id: casing.id
      }
    }),
    processes: servicesStore?.processes?.types?.map((type) => {
      return {
        title: type.title,
        id: type.id
      }
    }),
    bore_master: servicesStore?.bore_masters
      .filter((master) => {
        return objectsStore.activeObject?.bore_masters?.includes(master.id)
      })
      .map((master) => {
        return {
          title: master.title,
          id: master.id
        }
      }),
    bore_machine: servicesStore?.bore_machines
      .filter((machine) => {
        return objectsStore?.activeObject?.bore_machines?.includes(machine.id)
      })
      .map((master) => {
        return {
          title: master.title,
          id: master.id
        }
      }),
    type: servicesStore?.sample?.types?.map((type) => {
      return {
        title: type.title,
        id: type.id
      }
    })
  }
}

const relativeDegreesFields = {
  lat_plan: 'lat_plan_dms',
  lon_plan: 'lon_plan_dms',
  lat: 'lat_dms',
  lon: 'lon_dms'
}

const relativeDMSFields = {
  lat_plan_dms: 'lat_plan',
  lon_plan_dms: 'lon_plan',
  lat_dms: 'lat',
  lon_dms: 'lon'
}

export const transformDataList = (list, columns) => {
  return list.map((item) => {
    columns.forEach((column) => {
      let title
      if (column.type === 'autocomplete' || column.type === 'dropdown') {
        if (column.key === 'geologist') {
          title = getTitleByIDInSources(column.data, item[column.data], 'geologist')
        } else {
          title = getTitleByIDInSources(column.data, item[column.data])
        }
        item[column.data] = title
      }

      if (column.key === 'processes') {
        let string = ''
        if (Array.isArray(item[column.data])) {
          item[column.data].forEach((process, index) => {
            if (index === 0) {
              string += getTitleByIDInSources(column.data, process, column.key)
            } else {
              string += `, ${getTitleByIDInSources(column.data, process, column.key)}`
            }
          })
        }

        item[column.data] = string
      }

      if (column.key === 'coords_offset') {
        item[column.data] = item[column.data]?.toFixed(7)
      }

      if (relativeDMSFields[column.key]) {
        item[column.key] = decimalDegreesToDMS(item[relativeDMSFields[column.key]])
        item[relativeDegreesFields[column.key]] = item[column.key]
      }

      if (relativeDegreesFields[column.key]) {
        item[relativeDegreesFields[column.key]] = decimalDegreesToDMS(item[column.key])
      }
    })

    return item
  })
}

const getTitleByIDInSources = (key, id, columnKey) => {
  const servicesStore = useServicesStore()

  let foundedItem
  if (typeof id !== 'number') return id

  if (columnKey === 'geologist') {
    foundedItem = servicesStore.users.find((item) => item.id === id)
  } else {
    foundedItem = sources()[key].find((item) => item.id === id)
  }

  if (!foundedItem) return

  return foundedItem.title
}

let notificationShowed = false

export const showInputErrorNotification = (title, message) => {
  if (!notificationShowed) {
    appGlobalProperties.$notify({
      title,
      message,
      type: 'error'
    })

    notificationShowed = true

    setTimeout(() => {
      notificationShowed = false
    }, 2000)
  }
}

export const closeEditorsOnScrollHorizontally = (tableInstance) => {
  const editor = tableInstance?.getActiveEditor()
  if (editor && editor?.isOpened()) {
    editor.close()
    tableInstance.selectCell(editor.row, editor.col)
  }

  const dropdownMenuPlugin = tableInstance?.getPlugin('dropdownMenu')
  if (dropdownMenuPlugin && dropdownMenuPlugin?.menu) {
    const isMenuOpen = dropdownMenuPlugin?.menu?.isOpened()
    if (isMenuOpen) {
      dropdownMenuPlugin.menu.close()
    }
  }
}
