import { getUuid } from '..'
import { useRequests, useRitmDate } from '@/composables'
import { useExcavationStore } from '@/stores'

export const saveVideos = async ({ server_id, files }) => {
  const excavationStore = useExcavationStore()
  const rDate = useRitmDate()

  await Promise.all(
    files.map(async (video, i) => {
      const data = {
        server_id,
        video,
        uuid: getUuid()
      }
      const time = rDate(new Date()).format('iso')

      excavationStore.setField('loadingType', 'video')
      excavationStore.excavationAddItem('videoLoading', {
        id: time + i,
        uploaded: false
      })

      await saveVideoOnServer(data)

      excavationStore.excavationUpdateItem('videoLoading', {
        id: time + i,
        uploaded: true
      })
    })
  )
}

export const saveVideoOnServer = async ({ video, uuid, server_id }) => {
  try {
    const { postRequest } = useRequests()
    const { file, title } = video

    const data = {
      title,
      video: file,
      date_front: new Date(),
      uuid
    }
    await postRequest(`excavations/${server_id}/videos/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (e) {
    throw new Error('Произошла ошибка при загрузке видео')
  }
}

export const deleteVideos = async ({ files }) => {
  const { deleteRequest } = useRequests()

  try {
    await Promise.all(
      files.map(async ({ id }) => {
        await deleteRequest(`excavation-videos/${id}`)
      })
    )
  } catch (e) {
    throw new Error(e)
  }
}
